import React from 'react';
import { useSelector } from 'react-redux'
import WrongAnswer from './WrongAnswer.js'

export function WrongAnswers() {
    const wrongAnswers = useSelector((state) => state.gameReducer.wrongAnswers)
    const title = wrongAnswers.length > 0 ? <p>Your guesses:</p> : ''

    return (
        <div id="wrong-answers">
            {title}
            <div>{
                wrongAnswers.map((answer, i) => {
                    return <WrongAnswer key={i} answer={answer}/>
                })
            }</div>
        </div>
    ); 
}

export default WrongAnswers;
