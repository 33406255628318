import { configureStore } from '@reduxjs/toolkit'
import gameReducer from './gameSlice'
import viewReducer from './viewSlice'

export default configureStore({
  reducer: {
    gameReducer : gameReducer,
    viewReducer: viewReducer
  },
});
