import { createSlice } from '@reduxjs/toolkit'
import { gameNumber } from './config'
import { stats } from './cookie'

const hideHowToPlay = stats?.played === ''

export const viewSlice = createSlice({
    name: 'view',
    initialState: {
        background : {
            src : `images/cunt_img_${gameNumber}.jpg`
        },
        guessDisabled: false,
        hideHowToPlay: hideHowToPlay,
        hideSettings: true,
        hideWinPage: false,
        placeholder: 'Make a guess!'
    },
    reducers: {
        hideWinPage: (state) => {
            state.hideWinPage = true
        },
        setPlaceholder : (state, placeholder) => {
            state.placeholder = placeholder.payload
        },
        setGuessDisabled: (state, bool) => {
            state.guessDisabled = bool.payload
        },
        toggleWinPage: (state) => {
            state.hideWinPage = !state.hideWinPage
        },
        toggleInstructions: (state) => {
            state.hideHowToPlay = !state.hideHowToPlay
        },
        toggleSettings: (state) => {
            state.hideSettings = !state.hideSettings
        }
    }
})

export const { hideWinPage, setPlaceholder, setGuessDisabled, toggleWinPage, toggleInstructions, toggleSettings } = viewSlice.actions

export default viewSlice.reducer;
