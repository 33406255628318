import React from 'react'
import HowToPlay from './HowToPlay'
import StatsControl from './StatsControl'
import SettingsControl from './SettingsControl'

export function Header() {
    
    return(
        <div id="header">
            <HowToPlay />
            <div id="logo">
                <h1>Cuntle</h1>
            </div>
            <div id="controls">
                <StatsControl />
                <SettingsControl />
            </div>
        </div>
    )
}

export default Header