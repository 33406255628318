import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Switch } from '@mui/material';
import { changeMode } from '../app/gameSlice'

export function ModeController(props) {
    const modes = useSelector((state) => state.gameReducer.modes)
    const title = _.startCase(props.mode)
    const description = modes[props.mode].description
    const active = modes[props.mode].active
    const dispatch = useDispatch()

    const change = (event) => {
        dispatch(changeMode({mode: props.mode, active: event.target.checked}))
    }

    return(
        <div className="mode-controller">
            <div className="mode-description">
                <h3>{title} mode</h3>
                <p>{description}</p>
            </div>
            <div className="switch">
                <div>
                    <Switch checked={active} value={active} onChange={change} color="success" />
                </div>
            </div>
        </div>
    )
}

export default ModeController