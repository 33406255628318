import { createSlice } from '@reduxjs/toolkit'
import { answer } from '../app/answer'
import { stats, setCookie, setMode } from './cookie'
import { ignoreGameOver, tileAxisCount } from './config'
import _ from 'lodash'

const gameOver = stats?.played === new Date().toDateString() + (ignoreGameOver ? 'false' : '')
const squares = Array(tileAxisCount * tileAxisCount).fill({ clicked : false })
const mode = stats.mode

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    gameOver: gameOver,
    squares: squares,
    stats: stats,
    started : false,
    wrongAnswers: [],
    modes: {
      cunt: {
        description: 'Only one revealed square is visible at a time',
        active: mode === 'cunt'
      },
      hard: {
        description: 'Only 5 revealed squares are visible at a time',
        active: mode === 'hard'
      }
    }
  },
  reducers: {
    increment: (state, index) => {
        state.squares
          .filter(s => _.has(s, 'turnsSinceClick'))
          .forEach(s => s.turnsSinceClick++)
        if (!state.started) {
            state.started = true
        }  
        state.squares[index.payload].clicked = true
        state.squares[index.payload].turnsSinceClick = 0
    },
    checkGuess: (state, guess) => {
        if (answer.toLowerCase() === guess.payload.toLowerCase().trim()) {
            state.stats = setCookie(state.squares.filter(s => s.clicked).length)
            state.gameOver = true
            state.squares = Array(tileAxisCount * tileAxisCount).fill({ clicked : true })
        } else {
            return {
                ...state,
                wrongAnswers: [guess.payload.toLowerCase(), ...state.wrongAnswers]
             }
        }
    },
    changeMode: (state, mode) => {
      if (mode.payload.active) {
        state.modes.cunt.active = false
        state.modes.hard.active = false
      }
      state.modes[mode.payload.mode].active = mode.payload.active
      setMode(mode.payload.active ? mode.payload.mode : '')
    }
  }
})

export const { increment, checkGuess, changeMode } = gameSlice.actions

export default gameSlice.reducer;
