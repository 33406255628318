import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleInstructions } from '../app/viewSlice'

export function CloseInstructions() {
    const dispatch = useDispatch()

    const close = () => {
        dispatch(toggleInstructions())
    }

    return(
        <button id="close-instructions" onClick={close}>Enough of this shit!<br/>Let me guess the cunt!</button>
    )
}

export default CloseInstructions