import React from 'react'
import { useDispatch } from 'react-redux'
import { hideWinPage } from '../app/viewSlice'

export function CloseButton() {
    const dispatch = useDispatch()

    const hidePage = () => {
        dispatch(hideWinPage())
    }

    return(
        <div id="win-close">
            <p id="close-button" onClick={hidePage}>close</p>
        </div>
    )
}

export default CloseButton
