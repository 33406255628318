import { gameNumber } from './config'

const reveals = [
    [ 9, 18, 32, 26, 17, 12, 8, 30, 36, 38, 22, 33, 39, 15, 23, 29, 31, 24, 19, 25, 10, 11, 16, 40, 37, 4, 1, 5, 14, 44, 34, 45, 2, 20, 13, 43, 7, 47, 21, 35, 46, 3, 28, 41, 27, 0, 48, 6, 42 ],
    [ 31, 22, 30, 15, 36, 10, 12, 26, 38, 19, 33, 16, 24, 39, 11, 37, 18, 17, 32, 9, 23, 29, 25, 40, 8, 4, 2, 43, 46, 14, 47, 28, 5, 3, 20, 21, 13, 35, 34, 1, 45, 7, 41, 27, 44, 0, 42, 6, 48 ],
    [ 40, 12, 33, 10, 31, 9, 36, 23, 17, 22, 25, 29, 39, 37, 15, 32, 8, 38, 26, 11, 30, 24, 19, 18, 16, 3, 47, 5, 13, 7, 46, 35, 20, 21, 28, 27, 41, 14, 2, 44, 1, 45, 4, 43, 34, 42, 48, 0, 6 ],
    [ 18, 19, 10, 9, 24, 31, 39, 32, 38, 36, 29, 33, 23, 12, 37, 16, 26, 40, 22, 30, 11, 17, 25, 15, 8, 41, 46, 1, 44, 14, 21, 28, 5, 2, 13, 35, 34, 47, 43, 3, 4, 7, 45, 27, 20, 6, 0, 42, 48 ],
    [ 29, 9, 36, 30, 40, 37, 25, 38, 39, 19, 24, 26, 23, 8, 31, 15, 18, 16, 11, 17, 22, 12, 32, 33, 10, 45, 3, 21, 43, 14, 46, 47, 2, 41, 4, 44, 34, 7, 28, 5, 20, 13, 27, 1, 35, 48, 0, 42, 6 ],
    [ 26, 11, 38, 24, 9, 12, 29, 39, 33, 17, 19, 25, 30, 23, 8, 10, 40, 32, 16, 36, 18, 15, 37, 22, 31, 3, 5, 41, 34, 27, 28, 35, 46, 13, 2, 43, 21, 7, 47, 44, 20, 1, 45, 14, 4, 48, 42, 6, 0 ],
    [ 40, 18, 24, 16, 25, 9, 37, 32, 36, 33, 19, 23, 15, 39, 10, 29, 12, 22, 17, 31, 26, 38, 8, 30, 11, 3, 46, 21, 47, 41, 28, 27, 13, 7, 34, 14, 43, 4, 5, 20, 35, 2, 1, 44, 45, 6, 0, 42, 48 ],
    [ 22, 11, 37, 19, 26, 8, 16, 12, 15, 32, 38, 29, 40, 17, 31, 25, 18, 10, 33, 23, 24, 39, 9, 30, 36, 21, 1, 46, 45, 34, 41, 14, 35, 43, 13, 3, 4, 47, 2, 7, 5, 20, 44, 27, 28, 42, 0, 6, 48 ],
    [ 32, 38, 30, 12, 31, 37, 18, 8, 22, 16, 26, 15, 29, 36, 19, 17, 11, 40, 10, 25, 9, 24, 23, 33, 39, 46, 5, 21, 43, 47, 44, 3, 13, 20, 7, 41, 34, 28, 2, 14, 35, 45, 27, 1, 4, 48, 42, 6, 0 ],
    [ 18, 37, 36, 40, 15, 19, 30, 24, 29, 25, 17, 26, 8, 12, 9, 31, 38, 11, 10, 16, 39, 22, 32, 33, 23, 1, 47, 4, 7, 20, 14, 44, 28, 13, 35, 41, 2, 21, 5, 43, 34, 3, 27, 45, 46, 42, 6, 0, 48 ]
]
export const reveal = reveals[gameNumber % 10]