import React from 'react';
import { useSelector } from 'react-redux'
import Stats from './Stats'
import WinDetails from './WinDetails'
import CloseButton from './CloseButton'

export function WinPage () {
    const hideWinPage = useSelector((state) => state.viewReducer.hideWinPage) 
    const gameOver = useSelector((state) => state.gameReducer.gameOver) 
    const hidden = hideWinPage || !gameOver

    return (
      <div hidden={hidden} id="win-page">
          <div id="win-content">
            <CloseButton />
            <WinDetails />
            <Stats />
          </div>
      </div>
    );
}

export default WinPage;
