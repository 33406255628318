import React from 'react';
import { useSelector } from 'react-redux'
import { RevealButton } from './RevealButton'
import Header from './Header'
import Board from './Board';
import WrongAnswers from './WrongAnswers'
import CurrentGameStats from './CurrentGameStats'
import GuessInput from './GuessInput'
import ComeBack from './ComeBack'

export function GamePage() {
  const started = useSelector((state) => state.gameReducer.started)
  const gameOver = useSelector((state) => state.gameReducer.gameOver)
  const hideBegin = gameOver || started
  const hideStartedDiv = !started || gameOver
  const guessOrComeBack = gameOver ? <ComeBack /> : <GuessInput />

  return (
    <div id="game-page">
      <div id="main">
        <Header />
        <p>Guess the celebrity cunt everyday!</p>
        <Board />
        <RevealButton />
        {guessOrComeBack}
        <div hidden={hideBegin} id="not-started">Reveal square to begin!</div>
        <div hidden={hideStartedDiv} id="started">
          <CurrentGameStats />
          <WrongAnswers />
        </div>
      </div>
    </div>
  );
}

export default GamePage;
