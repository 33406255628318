import { DateTime } from "luxon"

const zeroDay = DateTime.local(2022, 4, 11)

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
})

const gameNumberOverride = () => {
    const parsed = parseInt(params.gameNumber);
    if (isNaN(parsed)) { return -1; }
    return parsed;
}
const overrideNumber = gameNumberOverride()
export const gameNumber = overrideNumber >= 0 ? overrideNumber : Math.floor(DateTime.now().diff(zeroDay, 'days').toObject().days)
export const ignoreGameOver = 'true' === params.ignoreGameOver
export const tileAxisCount = 7;
