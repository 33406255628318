import React from 'react';
import { useSelector } from 'react-redux'
import Square from './Square.js'

export function Board() {
    const image = useSelector((state) => state.viewReducer.background.src)
    const squares = useSelector((state) => state.gameReducer.squares)
    const gameOver = useSelector((state) => state.gameReducer.gameOver) 

    return (
        <div>
            <div id="board" style={{backgroundImage : `url(${image})`}}>
                <div id="grid">{
                    squares.map((element, i) => {
                        if (!gameOver) {
                            return <Square key={i} data={ {square: element,index: i} } ></Square>
                        }
                    })
                }
                </div>
            </div>
        </div>
    );
}

export default Board;
