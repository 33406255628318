import './App.css';
import Page from './components/Page.js'

function App() {
  return (
    <div className="App">
        <Page />
    </div>
  );
}

export default App;
