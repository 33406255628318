import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleSettings } from '../app/viewSlice'

export function SettingsControl() {
    const dispatch = useDispatch()

    const toggleSetting = () => {
        dispatch(toggleSettings())
    }

    return(
        <img src="images/settings.png" width="30" height="30" onClick={toggleSetting} />
    )
}

export default SettingsControl