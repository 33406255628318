import React from 'react';
import { useSelector } from 'react-redux'

export function Stats() {
    const stats = useSelector((state) => state.gameReducer.stats)
    const todayScore = [...stats.scores].pop()
    const gamesPlayed = stats.scores.length
    const avgScore = (stats.scores.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / gamesPlayed).toFixed(1)

    return (
        <div id="stats">
            <h2>Statistics</h2>
            <div id="stats-table">
                <div className="stats-row">
                    <div className="stat-cell stats-label">Today's Score:</div>
                    <div className="stat-cell stats-value">{todayScore}</div>
                </div>
                <div className="stats-row">
                    <div className="stat-cell stats-label">Games Played:</div>
                    <div className="stat-cell stats-value">{gamesPlayed}</div>
                </div>
                <div className="stats-row">
                    <div className="stat-cell stats-label">Average Score:</div>
                    <div className="stat-cell stats-value">{avgScore}</div>
                </div>
            </div>
        </div>
    )
}

export default Stats;
