import React from 'react'
import { useSelector } from 'react-redux'
import { answer } from '../app/answer'
import { share } from '../app/share'
import { gameNumber } from '../app/config'
import _ from 'lodash' 

export function WinDetails() {
    const squares = useSelector((state) => state.gameReducer.squares)
    const numGuesses = squares.filter(square => square.clicked).length
    const displayAnswer = _.startCase(_.toLower(answer))

    const shareResult = () => {
        share({numGuesses : numGuesses, gameNumber : gameNumber})
    }

    return(
        <div id="win-details">
            <h2>Congratulations!</h2>
            <p>You have correctly identified today's celebrity cunt as:</p>
            <h3>{displayAnswer}</h3>
            <button id="share" onClick={shareResult}>Share</button>
        </div>
    )
}

export default WinDetails
