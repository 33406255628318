import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleWinPage } from '../app/viewSlice'

export function StatsControl() {
    const dispatch = useDispatch()

    const toggleStats = () => {
        dispatch(toggleWinPage())
    }

    return(
        <img src="images/stats.png" width="30" height="30" onClick={toggleStats}/>
    )
}

export default StatsControl