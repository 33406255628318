import { stats } from './cookie'
import { gameNumber } from './config'

export function share() {
    const score = [...stats.scores].pop()

    const text = `#Cuntle ${gameNumber}\n\nI guessed today's cunt with ${score} square${score != 1 ? 's' : ''} revealed!\n\n`
    
    if (navigator.canShare) {
        navigator.share({
            url : 'https://cuntle.app',
            text : text
        })
    } else {
        navigator.clipboard
            .writeText(text)
            .then(function() {
                alert('Copied to clipboard')
            } , function() {
                alert('Sharing and copying to clipboard blocked by your browser')
            }) 
    }
} 