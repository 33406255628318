import React from 'react';
import _ from 'lodash' 

export function WrongAnswer(props) {

    return (
        <p className="wrong-answer">{_.startCase(_.toLower(props.answer))}</p>
    );
    
}

export default WrongAnswer;
