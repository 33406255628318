import React from 'react';
import { useSelector } from 'react-redux'
import WinPage from './WinPage'
import Instructions from './Instructions'
import GamePage from './GamePage'
import Settings from './Settings'

export function Page() {
  const hideSettings = useSelector((state) => state.viewReducer.hideSettings);
  const page = hideSettings ? <GamePage /> : <Settings />

  return (
    <section>
        <Instructions />
        <WinPage />
        {page}
    </section>
  );
}

export default Page;
