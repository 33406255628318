import React from 'react'
import Header from './Header'
import ModeController from './ModeController'

export function Settings() {
    return(
        <div id="settings-page">
            <div id="main">
                <Header />
                <ModeController mode="hard"/>
                <ModeController mode="cunt"/>
            </div>
        </div>
    )
}

export default Settings