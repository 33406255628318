import Cookies from 'js-cookie'

const cookie = Cookies.get('stats')
const emptyStats = {
    mode: '',
    played: '',
    scores: []
}
export const stats = cookie !== undefined ? JSON.parse(cookie) : emptyStats

export function setCookie(guesses) {

    const scores = stats?.scores || []

    const newStats = {
        ...stats, 
        played : new Date().toDateString(),
        scores : [...scores, guesses]
    }

    Cookies.set('stats', JSON.stringify(newStats), { expires: 3650 })

    return newStats
}

export function setMode(mode) {
    const newStats = {
        ...stats,
        mode : mode
    }
    Cookies.set('stats', JSON.stringify(newStats), { expires: 3650 })
}