import React from 'react'
import { useSelector } from 'react-redux'

export function CurrentGameStats() {
    const squares = useSelector((state) => state.gameReducer.squares)
    const numGuesses = squares.filter(s => s.clicked).length

    return(
        <div id="game-stats">
            <div>Squares Revealed:</div>
            <div id="guesses">{numGuesses}</div>
        </div>
    )
}

export default CurrentGameStats
