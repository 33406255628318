import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { checkGuess } from '../app/gameSlice'
import { setGuessDisabled, setPlaceholder } from '../app/viewSlice'

export function GuessInput() {
    const [input, setInput] = useState('')
    const placeholder = useSelector((state) => state.viewReducer.placeholder)
    const guessDisabled = useSelector((state) => state.viewReducer.guessDisabled)
    const started = useSelector((state) => state.gameReducer.started)
    const hideStartedDiv = !started
    const dispatch = useDispatch()

    const checkAnswer = (event) => {
        if (event.keyCode === 13) {
            dispatch(setPlaceholder('Try Again! Click a square...'))
            dispatch(setGuessDisabled(true))
            dispatch(checkGuess(input))
            event.target.blur()
            setInput('')
        }
    }

    const updateInput = (event) => {
        setInput(event.target.value)
    }

    return(
        <input 
            id="guess" 
            disabled={guessDisabled} 
            hidden={hideStartedDiv} 
            onInput={updateInput} 
            onKeyUp={checkAnswer} 
            placeholder={placeholder}
            value={input} />
    )
}

export default GuessInput
