import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleInstructions } from '../app/viewSlice'

export function HowToPlay() {
    const dispatch = useDispatch()

    const close = () => {
        dispatch(toggleInstructions())
    }

    return(
        <div id="how-to-play">
            <img src="images/qmark.png" width="30" height="30" onClick={close}/>
        </div>
    )
}

export default HowToPlay