import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { increment } from '../app/gameSlice'
import { setGuessDisabled, setPlaceholder } from '../app/viewSlice'
import { reveal } from '../app/revealArray'

export function RevealButton() {
    const squares = useSelector((state) => state.gameReducer.squares)
    const gameOver = useSelector((state) => state.gameReducer.gameOver)
    const numGuesses = squares.filter(s => s.clicked).length
    const revealNumber = reveal[numGuesses]
    const dispatch = useDispatch()
    
    const revealClick = () => {
        dispatch(increment(revealNumber))
        dispatch(setGuessDisabled(false))
        dispatch(setPlaceholder('Make a guess or reveal another square'))
    }
    
    return (
        <button hidden={gameOver} id="reveal-button" onClick={revealClick}>Reveal a square</button>
    )
}
 
export default RevealButton
