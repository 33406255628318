import React from 'react'
import { useSelector } from 'react-redux'
import CloseInstructions from './CloseInstructions'

export function Instructions() {
    const hideInstructions = !(useSelector((state) => state.viewReducer.hideHowToPlay)) 

    return(
        <div hidden={hideInstructions} id="instructions">
            <div id="instructions-content">
                <h2>How to Play</h2>
                <p>Hidden behind the squares is a celebrity cunt. The aim of the game is to guess the identity of the cunt by revealing as few squares as possible.</p>
                <p>Click the green "Reveal a square" button to reveal the first square and each square afterwards. Click the "Make a guess" box to type in your guess. If you make an incorrect guess you must reveal another square.</p>
                <p>To ensure fairness, every player has the squares revealed in the same order each day.</p>
                <CloseInstructions />
            </div>
        </div>
    )
}

export default Instructions