import React from 'react';
import { useSelector } from 'react-redux'
import { tileAxisCount } from '../app/config'
import _ from 'lodash'

export function Square(props) {
    const modes = useSelector((state) => state.gameReducer.modes)
    const turnLimit = !modes.cunt.active ? modes.hard.active ? 5 : (tileAxisCount * tileAxisCount) : 1
    const squareSize = (100 / tileAxisCount) + '%'
    const style = {
        height: squareSize,
        width: squareSize
    }

    const cssClass = () => {
        if (props.data.square.clicked) {
            if (props.data.square.turnsSinceClick >= turnLimit) {
                return 'square unflip'
            }
            return 'square flip'
        }
        return  'square'
    }

    return (
        <div 
            style={style}
            className={cssClass()} 
            >
        </div>
    );
    
}

export default Square;
